import axios, { AxiosInstance as ax, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpStatus, ResponseInterface } from '@/interfaces/ResponseInterface';
import { io } from 'socket.io-client';
import { showNotification } from '@/lib/utility';
import { sharedEntities } from 'shared/types';
import mitt from 'mitt';
import { EmitterEvents } from '@/lib/emitterEvents.ts';

export interface AxiosInstance extends ax {
	request: <T = any, R = AxiosResponse<ResponseInterface<T>>>(config: AxiosRequestConfig) => Promise<R>;

	get: <T = any, R = AxiosResponse<ResponseInterface<T>>>(url: string, config?: AxiosRequestConfig) => Promise<R>;

	delete: <T = any, R = AxiosResponse<ResponseInterface<T>>>(url: string, config?: AxiosRequestConfig) => Promise<R>;

	head: <T = any, R = AxiosResponse<ResponseInterface<T>>>(url: string, config?: AxiosRequestConfig) => Promise<R>;

	options: <T = any, R = AxiosResponse<ResponseInterface<T>>>(url: string, config?: AxiosRequestConfig) => Promise<R>;

	post: <T, R = AxiosResponse<ResponseInterface<T>>>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<R>;

	put: <T = any, R = AxiosResponse<ResponseInterface<T>>>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<R>;

	patch: <T = any, R = AxiosResponse<ResponseInterface<T>>>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<R>;
}

const errorCount: any = {};
const api: AxiosInstance = axios.create({ baseURL: import.meta.env.VITE_APP_API, withCredentials: true });
api.interceptors.response.use(
	(response) => {
		return new Promise((resolve, reject) => {
			if (response.status === HttpStatus.PARTIAL_CONTENT) {
				if (response.data.statusCode === HttpStatus.UNAUTHORIZED) {
					if (window.location.pathname !== '/auth') window.location.href = '/';
					showNotification({ data: response.data, caption: 'Unauthorized Access!' });
				} else if (response.data.statusCode === HttpStatus.FORBIDDEN) {
					localStorage.removeItem('isLogin');
					if (window.location.pathname !== '/auth') {
						localStorage.setItem('expectUrl', window.location.pathname);
						window.location.href = '/auth';
						localStorage.setItem(
							'notification',
							JSON.stringify({
								data: response.data,
								caption: 'Please login to access resource!',
								color: 'red',
							}),
						);
					}
				} else {
					/*showNotification({
						data: response.data,
						message: 'Unhandled error',
						type: 'negative',
						caption: `${capitalizeFirstLetter(response.data.exp, true)} (${response.data.statusCode})\n<br/>` + response.data.message,
					});*/
				}
				if (response.data.exp === 'NotFoundException') {
					console.warn(response.data);
					showNotification({
						message: 'URL not found',
						caption: 'Please check axios URL',
						color: 'red',
					});
				}
				return reject(response);
			}
			resolve(response);
		});
	},
	(error) => {
		if (error.message === 'Network Error') {
			if (errorCount[error.config.url] > 2) {
				showNotification({ caption: 'Please check your internet connection.', message: `${error.message}!`, color: 'red' });
				errorCount[error.config.url] = 0;
				return error;
			}
			errorCount[error.config.url] = errorCount[error.config.url] ? errorCount[error.config.url] + 1 : 1;
			setTimeout(() => {
				showNotification({
					caption: 'Please check your internet connection.',
					message: `${error.message}! Retrying in 5 sec...`,
					color: 'orange',
					loading: true,
				});
			}, 500);
			new Promise((resolve) => {
				setTimeout(() => {
					return resolve(null);
				}, 5000);
			}).then(() => {
				return api.request(error.config);
			});
		} else {
			return error;
		}
	},
);

const scraperApi: AxiosInstance = axios.create({ baseURL: import.meta.env.VITE_SCRAPER_API, withCredentials: true });
scraperApi.interceptors.response.use(
	(response) => {
		return new Promise((resolve, reject) => {
			if (response.status === HttpStatus.PARTIAL_CONTENT) {
				if (response.data.statusCode === HttpStatus.UNAUTHORIZED) {
					if (window.location.pathname !== '/auth') window.location.href = '/';
					showNotification({ data: response.data, caption: 'Unauthorized Access!' });
				} else if (response.data.statusCode === HttpStatus.FORBIDDEN) {
					localStorage.removeItem('isLogin');
					if (window.location.pathname !== '/auth') {
						localStorage.setItem('expectUrl', window.location.pathname);
						window.location.href = '/auth';
						localStorage.setItem(
							'notification',
							JSON.stringify({
								data: response.data,
								caption: 'Please login to access resource!',
								color: 'red',
							}),
						);
					}
				} else {
					/*showNotification({
						data: response.data,
						message: 'Unhandled error',
						type: 'negative',
						caption: `${capitalizeFirstLetter(response.data.exp, true)} (${response.data.statusCode})\n<br/>` + response.data.message,
					});*/
				}
				if (response.data.exp === 'NotFoundException') {
					console.warn(response.data);
					showNotification({
						message: 'URL not found',
						caption: 'Please check axios URL',
						color: 'red',
					});
				}
				return reject(response);
			}
			resolve(response);
		});
	},
	(error) => {
		if (error.message === 'Network Error') {
			setTimeout(() => {
				showNotification({
					caption: 'Please check your internet connection.',
					message: `${error.message}! Retrying in 5 sec...`,
					color: 'orange',
					loading: true,
				});
			}, 500);
			new Promise((resolve) => {
				setTimeout(() => {
					return resolve(null);
				}, 5000);
			}).then(() => {
				return scraperApi.request(error.config);
			});
		} else {
			return error;
		}
	},
);

export function getApiUrl(url: string): string {
	return `${import.meta.env.VITE_APP_API}/${url}`;
}

export function getFile(file: string, fallBack = ''): string {
	return isBase64(file) ? file : file ? `${import.meta.env.VITE_APP_API}/storage/file/${file}` : fallBack;
}

export const isBase64 = (str: string) => {
	return str?.includes(';base64,');
};

export async function getFileMeta(file: string): Promise<sharedEntities.StorageEntity> {
	return await fetch(`${import.meta.env.VITE_APP_API}/storage/meta/${file}`)
		.then((r) => r.json())
		.then((b) => b.body);
}

const socket = io(import.meta.env.VITE_SERVER_WS, { withCredentials: true });
const scraperSocket = io(import.meta.env.VITE_SCRAPER_WS, { withCredentials: true });
const emitter = mitt<EmitterEvents>();
export { api, socket, emitter, scraperSocket, scraperApi };
